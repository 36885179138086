
/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
	@supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
		html {
			min-height: 100.3%;
			overscroll-behavior-y: none;
		}
	}
}

/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
	@supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
		html {
			height: 100%;
			overflow: hidden;
		}
		body {
			margin: 0px;
			max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}
		/* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
	}
}
  
/* prevent pull-to-refresh for Chrome 63+ */
body{
	overscroll-behavior-y: none;
}

:root {
	--neutral-black1: #0c0e11;
	--neutral-black2: #36373A;
	--neutral-black3: #5C5D61;
	--neutral-white1: #f5f8f9;
	--neutral-gray2: #AFB2B5;
	--darkpurple: #16002E;
	--primary0: #260C3E;
	--primary1: #460C7C;
	--primary2: #623889;
	--primary6: #E5D4F4;
	--secondary2: #FF8FE6;
	--secondary3: #FFA6EB;
	--info-gradient: linear-gradient(90deg, rgba(255,121,225,0.5), rgb(168,0,131,0.5));
	--message-gradient: linear-gradient(90deg, rgba(66, 66, 66, 0.9), rgb(66, 66, 66,0.9));
}

#mytest {
	margin-left: 50px;
}

p {
	margin-right: 69px;
	--spatial-font-size: 18;
	--spatial-margin: 3;
	--spatial-color: "#000000";
}

.App {
	text-align: center;
}

.arrow {
	margin: 0;
	height: 87px;
	width: 114px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0.5;
}

.arrowEnabled {
	opacity: 1;
}

.arrowEnabled:hover {
	cursor: pointer;
	transform: scale(1.1);
	transition: transform 0.3s ease-out;
}

#arrowLeft {
	background-image: url(/public/assets/arrowLeft.svg);
	margin-right: 40px;
}

#arrowRight {
	background-image: url(/public/assets/arrowRight.svg);
	margin-right: 10px;
}

.babylonVRicon {
	position: fixed;
	top: 30px;
	right: 30px;
}

#backgroundFiller {
	height: 60vw;
	width: 100%;
	position: absolute;
	background-color: var(--neutral-black2);
}

#backgroundPanel {
	background-color: var(--neutral-black1);
}

.blob {
	position: absolute;
}

.blob img {
	width: 50vw;
}

#blurs {
	position: absolute;
	width: 100%;
	height: 100%;
}

#blurs div {
	position: absolute;
}

#blurs div img {
	width: 40vw;
	height: auto;
}

.blurCircle {
	top: 50%;
	left: 3%;
	transform: translateY(-50%);
	width: 23vw;
	height: 23vw;
	border-radius: 50%;
	filter: blur(4vw);
	background-color: var(--primary2);
}

#contactUs button:hover {
	cursor: pointer;
}

.client {
	display: inline-block;
	height: 76px;
	padding: 1.5vw 3vw 1.5vw 3vw;
}

#clients {
	background-image: linear-gradient(180deg, #0D0F12, #1B062F);
}

#clients > div {
	width: 80%;
	margin: 0 auto;
	padding-top: 2vw;
	padding-bottom: 2vw;
}

#companyDescr {
	background-image: linear-gradient(0deg, #1B062F, #0C0E11, #1B062F);
	width: 100%;
	height: 900px;
	clip-path: polygon(
		0 13%, 105px 0, 100% 0, 100% 100%, 105px 100%, 0 87%
	);
	position: absolute;
	top: 36vw;
	z-index: 2;
}

#companyDescrGrid {
	display: grid;
	column-gap: 30%;
	grid-template-columns: 2fr 2fr;
	grid-template-rows: 2fr 1.8fr;
	padding: 5%;
	height: 894px;
}

#companyDescrGrid div {
	z-index: 2;
}

#companyDescrGrid h1 {
	color: var(--neutral-white1);
	text-align: left;
	font-size: 38pt;
	position: relative;
	z-index: 1;
}

#companyDescrGrid img {
	width: 100%; 
}

#companyDescrGrid p {
	z-index: 1;
	position: relative;
	margin-right: 0;
}

#contactForm {
	text-align: left;
	margin: 2% 10% 2% 10%;
}

#contactMessage > div:nth-child(1) {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}

#contactMessage > div:nth-child(2) {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 auto;
	left: 0;
	right: 0;
	height: 255px;
	z-index: 3;
}

#contactMessage > div:nth-child(2) .feature {
	background-image: var(--message-gradient);
	margin: 0 auto;
	left: 0;
	right: 0;
	padding: 0;
	height: initial;
}

#contactMessage > div:nth-child(2) .feature p {
	font-size: 16pt;
	text-align: left;
	display: flex;
	justify-content: center;
}

#contactMessage > div:nth-child(2) .feature button {
	color: var(--neutral-black2);
	border: none;
	width: 200px;
	height: 60px;
	font: 20pt Montserrat;
	font-weight: bold;
	border-radius: 15px;
	margin-bottom: 40px;
}

#contactUs {
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 1fr;
	margin: 0 auto;
	background-color: var(--primary6);
}

#btnContact {
	background-color: var(--secondary2);
	color: white;
	border: none;
	width: 382px;
	height: 115px;
	font: 23pt Montserrat;
	font-weight: bold;
	border-radius: 15px;
	margin-top: 4vw;
	margin-bottom: 4vw;
	user-select: none;
}

#contactUs button:disabled, #contactUs button:disabled:hover {
	opacity: 0.5;
	cursor: auto;
	transform: none;
}

#contactUs button:hover {
	cursor: pointer;
	transform: scale(1.05);
	transition: transform 0.3s ease-out;
}

#contactUs div p {
	color: var(--neutral-black3);
	font-size: 28pt;
}

#contactUs h4 {
	padding-top: 3vw;
}

#contactFormGrid {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 80px;
}

#contactFormGrid div:nth-child(5), #contactFormGrid div:nth-child(6) {
	grid-column: 1 / span 2;
} 

#contactUs input[type=text] {
	width: 100%;
	background: linear-gradient(var(--neutral-gray2), var(--neutral-gray2)) center bottom 5px / calc(100% - 10px) 2px no-repeat;
	border: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 0;
	padding-right: 0;
	color: var(--neutral-black3);
	font: 20pt Montserrat;
}

#contactUs table {
	width: 100%;
}

#demoArrows {
	display: flex;
	justify-content: flex-end;
}

.demoItem {
	display: inline-block;
	height: 30vw;
	padding-right: 2.3%
}

.demoItem h1 {
	color: var(--primary6);
}

.demoItem img {
	height: 90%;
}

.demoItem img:hover {
	transform: scale(1.05);
	transition: transform 0.3s ease-out;
	cursor: pointer;
}

#demosContainer {
	height: 36vw;
	overflow: hidden;
	margin: 0 auto;
}

#demosGrid {
	display: grid;
	align-items: center;
	grid-template-columns: 5fr 1fr;
}

#demosScroller {
	height: 30vw;
	width: 213%;
}

#demos {
	overflow: hidden;
	position: relative;
	text-align: left;
	width: 80%;
	margin: 0 auto;
}

#companyDescrGrid p {
	color: var(--neutral-gray2);
	font-size: 25pt;
	text-align: left;
}

h3 {
	color: var(--neutral-black1);
	font: 38pt Futura;
	font-weight: bold;
}

h4 {
	color: var(--neutral-black1);
	font: 25pt Montserrat;
	font-weight: bold;
	margin: 0;
}

#headerBanner {
	height: 60vw;
	width: 100%;
	position: relative;
	pointer-events: none;
	top: -7vw;
}

#innerBorder {
	position: absolute;
	height: 834px;
	width: calc(100vw - 70px);
	background-color: var(--primary2);
	margin: 0 auto;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	clip-path: polygon(96px 827px, 10px 731px, 10px 102px, 92px 11px, calc(100% - 9px) 11px, calc(100% - 9px) 826px, 99% 830px, calc(100% - 2px) 827px, calc(100% - 2px) 4px, 88px 4px, 2px 100px, 2px 734px, 92px 835px, calc(100% - 2px) 840px, calc(100% - 2px) 826px);
}

.feature {
	width: 595px;
	position: absolute;
}

.features > div {
	margin-bottom: 130px;
}

.features {
	width: 80%;
	display: grid;
	grid-template-columns:1fr 1fr 1fr;
	margin: 0 auto;
	padding-top: 3vw;
	padding-bottom: 3vw;
}

.feature {
	background-image: var(--info-gradient);
	clip-path: polygon(64px 0%, 100% 0%, 100% calc(100% - 50px), calc(100% - 64px) 100%, 0% 100%, 0% 50px);
	height: 340px;
	width: 595px;
	box-sizing: border-box;
	padding: 10%;
	margin-bottom: 130px;
	z-index: 1;
}

.features h2 {
	font-size: 22pt;
	text-align: left;
	padding: 0;
	margin: 0;
}

.featureHeading {
	display: grid;
	column-gap: 5%;
	grid-template-columns: 1fr 8fr;
	align-items: center;
}

.features img {
	width: 48px;
	height: auto;
}

.features p {
	margin-right: initial;
	color: var(--neutral-white1);
	text-align: left;
	font-size: 19pt ;
}

@font-face {
	font-family: "Futura";
	font-style: normal;
	font-weight: bold;
	src: url(/public/assets/fonts/futura.ttf) format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	src: url(/public/assets/fonts/Montserrat-Light.ttf) format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bold;
	src: url(/public/assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

h1 {
	color: var(--neutral-white1);
	font: 47pt Futura;
	font-weight: bold;
	text-transform: uppercase;
	--spatial-font-weight: 600;
	--spatial-font-size: 30;
	--spatial-margin: 3;
	--spatial-color: "#000000";
}

h2 {
	color: var(--neutral-white1);
	font: 20pt Futura;
	font-weight: bold;
}

#honeyComb {
	position: absolute;
	top: calc(-23vw + 800px);
	left: 0;
	background-image: url(/public/assets/honeycomb.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 765px;
	width: 100%;
	z-index: 1;
}

.blink1 {
	animation: blinker1 0.1s normal forwards ease-in-out;
}
  
@keyframes blinker1 {
	0% { background-color: black; }
	30% { background-color: var(--darkpurple); }
	100% { background-color: black; }
}

.blink2 {
	animation: blinker2 0.1s normal forwards ease-in-out;
}
  
@keyframes blinker2 {
	0% { background-color: black; }
	30% { background-color: var(--primary0); }
	100% { background-color: black; }
}
  
#imageCaption {
	z-index: 2;
	position: absolute;
	font: 24pt Futura;
	color: var(--primary6);
	margin: 0 auto;
	left: 0;
	right: 0;
	bottom: 40px;
}

#imageContainer {
	background-color: black;
	position: relative;
	max-height: 1200px;
}

#loading {
	position: absolute;
	background-image: url(/public/assets/marcus.jpg);
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

#mediumPercent {
	background-image: linear-gradient(-45deg, rgba(255,121,225,0.5), rgba(168,0,131,0.5));
	clip-path: polygon(10% 0%,100% 0%,100% 80%,90% 100%,0% 100%,0% 20%);	
}

#mediumStats {
	position: relative;
	height: 1000px;
	background-image: linear-gradient(0deg, #0C0E11, #1B062F);	
}

#mediumStats div {
	position: absolute;
	height: 638px;
	top: 230px;
	z-index: 2;
}

#mediumStats div:nth-child(1) {
	left: calc(50% - 369px);
}

#mediumStats div:nth-child(2) {
	right: calc(50% - 369px);
}

#mediumStats img {
	height: 100%;
}

#maddieCanvas {
	position: relative;
	top: -70px;
	left: -70px;
	width: 550px;
	height: 700px;
}

#maddieCanvas:focus {
	outline: none;
}

#maddieCanvas:hover {
	cursor: pointer;
}

#myCanvas {
	width: 100%;
	height: 100%;
}

#myCanvas:hover {
	cursor: pointer;
}

p {
	color: var(--neutral-gray2);
	font: 12pt Montserrat;
	font-weight: normal;
}

.statContainer {
	position: absolute;
	width: 280px;
	z-index: 1;
}

.statistic {
	background-image: var(--info-gradient);
	clip-path: polygon(10% 0%,100% 0%,100% 80%,90% 100%,0% 100%,0% 20%);
	z-index: 2;
	position: relative;
	padding: 5% 15% 5% 10%;
}

.statistic h2 {
	margin: 8px;
	font-size: 38pt;
}

.statistic hr {
	border-color: var(--secondary3);
	margin: 0;
}

.statistic img {
	width: 46px;
}

.statistic p {
	color: var(--neutral-white1);
	font-weight: bold;
	font-size: 17pt;
	margin-bottom: 13px;
	margin-top: 18px;
}

#videoBanner {
	width: 100%;
	height: 165%;
	object-fit: contain;
}

#VR {
	width: 100%;
	background-color: var(--primary6);
	position: relative;
	margin-bottom: 8%;
	z-index: 1;
}

#VR .service h1 {
	color: var(--neutral-black1);	
}

#VR .service p {
	color: var(--neutral-black2);	
}

#VR .service .chartContainer {
	margin: 10px 0 5% 5%;
	background-color: var(--primary0);
	clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 83%, 0 0);
	padding: 2%;
}

.service {
	width: 80%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 0 auto;
	position: relative;
}

.serviceText {
	text-align: left;
	margin-right: 7vw;
}

.serviceImage img {
	text-align: left;
	width: 99%;
	clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
}

.serviceImageMain img {
	text-align: left;
	width: 99%;
	clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 87%, 0 0);
}

.serviceImageReverse img {
	text-align: right;
	width: 99%;
	clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 87%, 0 0);
}

.serviceTextReverse {
	text-align: left;
	margin-left: 7vw;
}

.service p {
	font-size: 23pt;
}

.statContainer {
	padding-top: calc(700px - 23vw);
	left: 20%;
	top: 21%;
}

.statContainer ~ .statContainer {
	right: 20%;
	top: 40%;
	left: initial;
}

#VRcaption {
	text-align: right;
	color: var(--neutral-black1);
	font: 19pt Futura;
	font-weight: bold;
}

#VRcontainer {
	width: 1100px;
}

#VRinfo {
	margin: 5% 0 0 0;
}

#VRinfo img {
	width: 78%;
}

@media only screen and (max-width: 2300px) {
	.feature {
		width: 500px;
	}
}

@media only screen and (max-width: 2150px) {
	h1 {
		font-size: 30pt;
	}
}

@media only screen and (max-width: 1900px) {
	.feature {
		width: 25vw;
	}
	.statContainer {
		left: 10%;
	}
	.statContainer ~ .statContainer {
		right: 10%;
	}
}

@media only screen and (max-width: 1820px) {
	#companyDescrGrid {
		column-gap: 10%;
	}
}

@media only screen and (max-width: 1660px) {
	#VRcontainer {
		width: 100%;
	}
}

@media only screen and (max-width: 1430px) {
	#companyDescrGrid div:nth-child(1) {
		position: relative;
		top: 100px;
	}
	#companyDescrGrid div:nth-child(2) {
		position: relative;
		top: 100px;
	}
	#companyDescrGrid p {
		font-size: 19pt;
	}
	.statContainer {
		left: 10px;
	}
	.statContainer ~ .statContainer {
		right: 10px;
	}
}

@media only screen and (max-width: 1400px) {
	.features p {
		font-size: 17pt;
	}
}

@media only screen and (max-width: 1145px) {
	.features p {
		font-size: 16pt;
	}
}

@media only screen and (max-width: 1180px) {
	h1 {
		font-size: 20pt;
	}
	h4 {
		font-size: 20pt;
	}
	.arrow {
		height: 60px;
	}
	.service p {
		font-size: 15pt;
	}
	.statistic {
		transform: scale(0.7);
	}
	#contactUs div p {
		font-size: 15pt;
	}
}

@media only screen and (max-width: 1180px) {
	.features h2 {
		font-size: 19pt;
	}
	.features p {
		font-size: 17pt;
	}
	.features img {
		width: 35px;
	}
	#VRcaption {
		font-size: 15pt;
	}
}

@media only screen and (max-width: 1000px) {
	/* mobile UI */
	#arrowLeft {
		margin-right: 0
	}
	#companyDescr {
		height: 900px;
		clip-path: polygon(0 13%, 105px 0, 100% 0, 100% 100%, 105px 100%, 0 87%);
	}
	#companyDescrGrid {
		display: block;
		padding-left: 12%;
		padding-right: 12%;
	}
	#companyDescrGrid div:nth-child(1) {
		top: 50px;
		margin-bottom: calc(90px - 2vw);
	}
	#companyDescrGrid div:nth-child(2) {
		position: initial;
	}
	#companyDescrGrid h1 {
		font-size: 27pt;
	}
	#companyDescrGrid p {
		font-size: 16pt;
	}
	#companyDescrGrid img {
		width: 70%;
	}
	#contactForm {
		width: 95%;
		margin: 0;
	}
	#contactUs {
		display: flex;
		justify-content: center;
	}
	#contactUs input[type=text] {
		font-size: 18pt;
	}
	#demos {
		width: 92%;
	}
	#demosContainer {
		height: 56vw;
	}
	#demosScroller {
		width: 300%;
	}
	.demoItem {
		height: 50vw;
	}
	#demoScroller {
		height: 50vw;
		width: 413%;
	}
	.features {
		width: 92%;
		display: block;
	}
	.features h2 {
		font-size: 24pt;
	}
	.features img {
		width: 65px;
	}
	.features p {
		font-size: 19pt;
	}
	.feature {
		width: 100%;
	}
	#honeyComb {
		top: calc(-23vw + 1000px);
		background-image: url(/public/assets/honeycomb_mobile.svg);
		background-size: 900px;
		height: 1465px;
	}
	#mediumStats {
		height: 1600px;
	}
	#mediumStats div {
		position: relative;
	}
	#mediumStats div:nth-child(1) {
		position: absolute;
		right: initial;
		left: calc(50% - 80px);
	}
	#mediumStats div:nth-child(2) {
		position: absolute;
		left: initial;
		right: calc(50% - 60px);
		top: 900px;
	}
	.service {
		display: flex;
		flex-direction: column;
		width: 92%;
	}
	#demos h1, .service h1 {
		font-size: 35pt;
	}
	.service p {
		font-size: 20pt;
	}
	.serviceImageReverse {
		order: 2;
	}
	.serviceText {
		padding-top: 20px;
	}
	.serviceTextReverse {
		margin-left: 0;
	}
	.statContainer {
		top: 15%;
		right: initial;
		left: calc(50% - 350px);
	}
	.statContainer ~ .statContainer {
		top: 48%;
		left: initial;
		right: calc(50% - 340px);
	}
	#imageContainer {
		display: none;
	}
	#innerBorder {
		height: 845px;
		clip-path: polygon(96px 827px, 10px 731px, 10px 102px, 92px 11px, calc(100% - 10px) 11px, calc(100% - 11px) 826px, 99% 830px, calc(100% - 2px) 827px, calc(100% - 2px) 4px, 88px 4px, 2px 100px, 2px 734px, 92px 835px, calc(100% - 2px) 835px, calc(100% - 2px) 826px);		
	}
	.statistic {
		transform: none;
	}
	#VR {
		padding-top: 20px;
	}
	#VR .service .chartContainer {
		margin: auto;
	}
	#VRcontainer {
		padding-bottom: 60px;
	}
	#VRinfo {
		padding-bottom: 30px;
	}
}

@media only screen and (max-width: 700px) {
	.statContainer {
		transform: scale(0.6);
	}
	.statContainer {
		left: calc(50% - 230px);
	}
	.statContainer ~ .statContainer {
		right: calc(50% - 210px);
	}
	#maddieCanvas {
		position: relative;
		top: -70px;
		left: -70px;
		width: 500px;
		height: 600px;
	}
	#mediumStats {
		height: 1400px;
	}
	#mediumStats div:nth-child(2) {
		top: 800px;
	}
	#mediumStats img {
		height: 80%;
	}
	.client {
		height: 50px;
	}
	#contactFormGrid div:last-child {
		text-align: center;
	}
	#contactFormGrid {
		display: block;
	}
	#demos .demoItem h1 {
		font-size: 15pt;
	}
}

@media only screen and (max-width: 540px) {
	#companyDescrGrid {
		padding-left: 15%;
		padding-right: 15%;
	}
	#companyDescrGrid p {
		font-size: 14pt;
	}
	#demosGrid {
		display: block;
	}
	#demoArrows {
		margin: 0 auto;
		margin-bottom: 40px;
		width: 250px;
	}
	#demosScroller {
		width: 400%;
	}
}

@media only screen and (max-width: 450px) {
	#companyDescrGrid img {
		margin-left: 10vw;
	}
	h1 {
		font-size: 25pt;
	}
	#companyDescrGrid h1 {
		font-size: 24pt;
	}
	#maddieCanvas {
		position: relative;
		top: 15px;
		left: -70px;
		width: 450px;
		height: 500px;
	}
	#mediumStats {
		height: 1200px;
	}
	#mediumStats img {
		height: 70%;
	}
	#mediumStats div:nth-child(2) {
		top: 700px;
	}
	.statContainer {
		top: 25%;
	}
}

@media only screen and (max-width: 430px) {
	.service h1 {
		font-size: 25pt;
	}
	.client {
		height: 40px;
	}
	#contactUs button {
		width: 300px;
		height: 100px;
	}
}

@media only screen and (max-width: 400px) {
	h3 {
		font-size: 36pt;
	}
}